import React from "react";
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import styles from './storyTemplate.module.css';
import Layout from "../components/layout";

export default ( { data } ) => {
  const { markdownRemark, file } = data; // props.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout coverImage={frontmatter.coverImage ? frontmatter.coverImage.childImageSharp.fluid : file.childImageSharp.fluid }>
      <Helmet>
        <title>{`${frontmatter.title} - Silbar`}</title>
      </Helmet>
      <article className={styles.story}>
        <div className={styles.headerFlow}>
          <header className={styles.storyHeader}>
            <h1 className={styles.title}>{frontmatter.title}</h1>
            {frontmatter.subtitle && <h2 className={styles.subtitle}>{frontmatter.subtitle}</h2>}
          </header>
          <time dateTime={frontmatter.dateRaw} className={styles.time}>
            <span className={styles.day}>{frontmatter.day}</span>
            <span className={styles.month}>{frontmatter.month}</span>
            <span className={styles.year}>{frontmatter.year}</span>
          </time>
        </div>
        <div
          className={`${styles.text} ${frontmatter.isPoem ? styles.poem : ''}`}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        dateRaw: date
        day: date(formatString: "DD")
        month: date(formatString: "MMMM", locale: "nl")
        year: date(formatString: "YYYY")
        date(formatString: "DD MMMM YYYY", locale: "nl")
        path
        title
        subtitle
        isPoem
        coverImage {
          childImageSharp {
            fluid(
              maxWidth: 1920
              quality: 75
            ) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        coverImagePosition
      }
    },
    file(relativePath: { eq: "placeholder.jpg" }) {
      childImageSharp {
        fluid(
          quality: 75,
          maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
